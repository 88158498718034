/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Venue
 */
export interface Venue {
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    division?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    circuit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    district?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Venue
     */
    searchNames?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Venue
     */
    isFederal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    relatedJurisdictionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    footerFormat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    templateFile?: string | null;
}

export function VenueFromJSON(json: any): Venue {
    return VenueFromJSONTyped(json, false);
}

export function VenueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Venue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'division': !exists(json, 'division') ? undefined : json['division'],
        'circuit': !exists(json, 'circuit') ? undefined : json['circuit'],
        'district': !exists(json, 'district') ? undefined : json['district'],
        'searchNames': !exists(json, 'searchNames') ? undefined : json['searchNames'],
        'isFederal': !exists(json, 'isFederal') ? undefined : json['isFederal'],
        'relatedJurisdictionId': !exists(json, 'relatedJurisdictionId') ? undefined : json['relatedJurisdictionId'],
        'footerFormat': !exists(json, 'footerFormat') ? undefined : json['footerFormat'],
        'templateFile': !exists(json, 'templateFile') ? undefined : json['templateFile'],
    };
}

export function VenueToJSON(value?: Venue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fullName': value.fullName,
        'shortName': value.shortName,
        'division': value.division,
        'circuit': value.circuit,
        'district': value.district,
        'searchNames': value.searchNames,
        'isFederal': value.isFederal,
        'relatedJurisdictionId': value.relatedJurisdictionId,
        'footerFormat': value.footerFormat,
        'templateFile': value.templateFile,
    };
}


