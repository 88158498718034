/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormData
 */
export interface FormData {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FormData
     */
    formFields?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    firmAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    firmCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    attorneyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    attorneyBarNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    attorneyEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    attorneyBarContact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    opposingCounselInformation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    opposingCounselInformationTwo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    opposingCounselType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    firmCounty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    firmFaxNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    firmPhoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    caseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    caseName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    clientName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    judgeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    relatedDocumentTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    respondingPartyType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    courtCounty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    respondingParty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormData
     */
    propoundingParty?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FormData
     */
    currentRequests?: number;
    /**
     * 
     * @type {number}
     * @memberof FormData
     */
    priorRequests?: number;
}

export function FormDataFromJSON(json: any): FormData {
    return FormDataFromJSONTyped(json, false);
}

export function FormDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formFields': !exists(json, 'formFields') ? undefined : json['formFields'],
        'firmAddress': !exists(json, 'firm_address') ? undefined : json['firm_address'],
        'firmCity': !exists(json, 'firm_city') ? undefined : json['firm_city'],
        'attorneyName': !exists(json, 'attorney_name') ? undefined : json['attorney_name'],
        'attorneyBarNumber': !exists(json, 'attorney_bar_number') ? undefined : json['attorney_bar_number'],
        'attorneyEmail': !exists(json, 'attorney_email') ? undefined : json['attorney_email'],
        'attorneyBarContact': !exists(json, 'attorney_bar_contact') ? undefined : json['attorney_bar_contact'],
        'opposingCounselInformation': !exists(json, 'opposing_counsel_information') ? undefined : json['opposing_counsel_information'],
        'opposingCounselInformationTwo': !exists(json, 'opposing_counsel_information_two') ? undefined : json['opposing_counsel_information_two'],
        'opposingCounselType': !exists(json, 'opposing_counsel_type') ? undefined : json['opposing_counsel_type'],
        'firmCounty': !exists(json, 'firm_county') ? undefined : json['firm_county'],
        'firmFaxNumber': !exists(json, 'firm_fax_number') ? undefined : json['firm_fax_number'],
        'firmPhoneNumber': !exists(json, 'firm_phone_number') ? undefined : json['firm_phone_number'],
        'caseNumber': !exists(json, 'case_number') ? undefined : json['case_number'],
        'caseName': !exists(json, 'case_name') ? undefined : json['case_name'],
        'clientName': !exists(json, 'client_name') ? undefined : json['client_name'],
        'judgeName': !exists(json, 'judge_name') ? undefined : json['judge_name'],
        'relatedDocumentTitle': !exists(json, 'related_document_title') ? undefined : json['related_document_title'],
        'respondingPartyType': !exists(json, 'responding_party_type') ? undefined : json['responding_party_type'],
        'courtCounty': !exists(json, 'court_county') ? undefined : json['court_county'],
        'respondingParty': !exists(json, 'respondingParty') ? undefined : json['respondingParty'],
        'propoundingParty': !exists(json, 'propoundingParty') ? undefined : json['propoundingParty'],
        'currentRequests': !exists(json, 'currentRequests') ? undefined : json['currentRequests'],
        'priorRequests': !exists(json, 'priorRequests') ? undefined : json['priorRequests'],
    };
}

export function FormDataToJSON(value?: FormData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formFields': value.formFields,
        'firm_address': value.firmAddress,
        'firm_city': value.firmCity,
        'attorney_name': value.attorneyName,
        'attorney_bar_number': value.attorneyBarNumber,
        'attorney_email': value.attorneyEmail,
        'attorney_bar_contact': value.attorneyBarContact,
        'opposing_counsel_information': value.opposingCounselInformation,
        'opposing_counsel_information_two': value.opposingCounselInformationTwo,
        'opposing_counsel_type': value.opposingCounselType,
        'firm_county': value.firmCounty,
        'firm_fax_number': value.firmFaxNumber,
        'firm_phone_number': value.firmPhoneNumber,
        'case_number': value.caseNumber,
        'case_name': value.caseName,
        'client_name': value.clientName,
        'judge_name': value.judgeName,
        'related_document_title': value.relatedDocumentTitle,
        'responding_party_type': value.respondingPartyType,
        'court_county': value.courtCounty,
        'respondingParty': value.respondingParty,
        'propoundingParty': value.propoundingParty,
        'currentRequests': value.currentRequests,
        'priorRequests': value.priorRequests,
    };
}


