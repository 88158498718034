import Loading from "components/Loading";
import { useUserApi } from "hooks/useApi";
import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import "./JurisdictionSelect.scss";
import { ReactComponent as ConstructionIcon } from '../../images/Construction_illustration.svg';
import Button from "components/Button";
import { FirmClient } from "services/FirmService";
import { useAuth } from "hooks/useAuth";

export default function JurisdictionSelect({ firm, addOffice }: { firm?: FirmClient, addOffice: (firm: FirmClient, jurisdiction: string, state: string) => void }) {
  const [useSuggested, setUseSuggested] = useState(true);
  const [otherJurisdiction, setOtherJurisdiction] = useState<string>();
  const [sourceState, setSourceState] = useState<string>();
  const [saving, setSaving] = useState(false);
  const { jurisdictions } = useAuth()!;
  const federalJxId = "6652fce2-272b-4c70-b4a8-9b84642a465f"
  const filteredJurisdictions = jurisdictions?.filter(j => j.id !== federalJxId);
  const suggestedJx = filteredJurisdictions && sourceState ? filteredJurisdictions.find(x => x.abbreviation === sourceState) ?? filteredJurisdictions.find(x => x.abbreviation === "CA") : undefined;
  const otherJx = (filteredJurisdictions && !!otherJurisdiction) ? filteredJurisdictions.find(x => x.id === otherJurisdiction) : undefined;
  const userApi = useUserApi();

  const selectedIsUnSupported = (!useSuggested && otherJx && !otherJx?.isFullySupported) || (useSuggested && !suggestedJx?.isFullySupported);

  useEffect(() => {
    async function load() {
      setSourceState((await userApi.userLocation()) || "CA");
    }
    load();
  }, [userApi]);

  async function submitJurisdiction() {
    setSaving(true);
    addOffice(firm!, useSuggested ? suggestedJx?.id! : otherJx?.id!, useSuggested ? suggestedJx?.abbreviation! : otherJx?.abbreviation!);
  }

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <div className="jurisdiction-select-container d-flex flex-column justify-content-center align-items-center text-center">
        <Loading isLoading={!suggestedJx}>
          <h2>{`It looks like you're in ${suggestedJx?.friendlyName}. Is that correct?`}</h2>
          <p>Knowing your location allows us to provide a more accurate and tailored experience.</p>
          <div className="jurisdiction-select d-flex flex-column justify-content-left align-items-center">
            <div className="mb-8">
              <Form.Check name="use-suggested" id="suggested-yes" type='radio' checked={useSuggested} label={`Yes, I'm in ${suggestedJx?.friendlyName ?? suggestedJx?.name}`} onChange={() => setUseSuggested(true)} />
              <Form.Check name="use-suggested" id="suggested-no" type='radio' checked={!useSuggested} label="No, I'm in Another State" onChange={() => setUseSuggested(false)} />
            </div>
            {!useSuggested &&
              <div className="mb-8 state-select">
                <label htmlFor="select-jx">Select Your State</label>
                <Form.Select id='select-jx' value={otherJurisdiction ?? ''} onChange={(e) => setOtherJurisdiction(e.target.value)}>
                  <option key="select" value="" disabled>Select</option>
                  {filteredJurisdictions?.sort((a, b) => a.friendlyName!.localeCompare(b.friendlyName!)).filter(x => x.id !== suggestedJx?.id).map(x => {
                    return (
                      <option key={x.id} value={x.id}>{x.friendlyName}</option>
                    );
                  })}
                </Form.Select></div>}
          </div>
          {selectedIsUnSupported &&
            <div className="not-supported justify-content-left">
              <h3><ConstructionIcon width={120} height={120} /></h3>
              <h3>We’re building in your jurisdiction!</h3>
              <p>To celebrate our new functionality in your jurisdiction, <b>we’re adding 4 free document credits to your account</b>.</p>
              <p>The more requests and interrogatories you upload to Briefpoint, the faster Briefpoint learns your jurisdiction.</p>
              <p>Note that because we just launched in your jurisdiction, the documents you download <b>may not conform with your jurisdiction’s formatting requirements</b>. Please double-check your local drafting rules and ensure your documents are properly formatted before serving. By continuing you acknowledge this limitation.</p>
              <p>Contact <a href="mailto:support@briefpoint.ai?subject=Limited Jurisdiction Support">support@briefpoint.ai</a> for more information.</p>
            </div>}
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Button variant="primary" disabled={saving || (!useSuggested && !otherJurisdiction)} onClick={submitJurisdiction} loading={saving} text="Localizing...">{`${selectedIsUnSupported ? 'I Understand' : 'Continue'}`}</Button>
          </div>
        </Loading>
      </div>
    </Container>
  );
}
