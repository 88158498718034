import { Case, Client, DocumentSubType, Jurisdiction, Party, PartyPosition } from 'briefpoint-client';
import { Modal } from 'components/Modals';
import TagsSelect, { TagOption } from 'components/Select/TagsSelect';
import './SelectPartyModal.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { OptionsType, ActionMeta } from 'react-select';
import { getRequestTypeSingular } from 'screens/DocumentWizard/SpecialInterrogatoriesStep/Selections/utils';
import { useCaseApi, useClientApi } from 'hooks/useApi';
import useConfig, { MULTI_CLIENT_FF } from "hooks/useConfig";
import { useAuth } from 'hooks/useAuth';
import { Typeahead } from "react-bootstrap-typeahead";
import { type Option } from "react-bootstrap-typeahead/types/types";

const defaultTags = new Map<string, string>([
  ['27f31778-e733-4250-a5ca-3481fec1bcad', 'Driver'], //AutoTort
  ['2b0a2fa1-5792-41bf-b790-744529887768', 'Employer'], //Employment
  ['e0fdb3a3-fde2-4a01-b5d9-9efd1f437ac0', 'Owner'], //Other PI/PD/WD Tort: Asbestos
  ['21df6854-1668-4fda-92a9-ff54d244e320', 'Manufacturer'], //Other PI/PD/WD Tort: Product Liability
  ['e7784dbc-09ce-4ca6-bfca-1ca706284e27', 'Care Provider'], //Other PI/PD/WD Tort: Medical Malpractice
  ['f9440220-445c-47a9-9e31-a61330f06ba8', 'Business'], //Non-PI/PD/WD Tort: Business Tort/Unfair Business Practice
  ['d6db8523-363e-4d73-b4bd-5a0d932c6a04', 'Professional'], //Non-PI/PD/WD Tort: Professional Negligence
  ['78fe7cf1-595b-4072-8522-34baadbe28e3', 'Debtor'], //Contract: Rule 3.740 Collections
  ['12a362d3-6d25-4b0f-982b-52c8974e0912', 'Debtor'], //Contract: Other Collections
  ['de59fcb2-e41a-453d-94ba-df93f1310e77', 'Insurance Company'], //Contract: Insurance Coverage
  ['b5b00d6c-baeb-47e3-ad6e-eb17c45ffca6', 'Government'], //Real Property: Eminent Domain/Inverse Condemnation
  ['ba588580-a386-464f-b21c-310482e86538', 'Landlord'], //Real Property: Wronful Eviction
  ['9427abc5-ff61-466d-baf8-a8d30b557174', 'Landlord'], //Real Property: Other Real Property
  ['4bef1bbd-b6e6-4ab6-9393-46d2ac10d264', 'Tenant'], //Unlawful Detainer
]);

interface Props {
  show: boolean;
  _case: Case;
  clients?: Client[];
  type?: DocumentSubType;
  availableTags: Map<number, string>;
  jurisdiction: Jurisdiction;
  onClose: () => void;
  onConfirm: (party: Party, client?: Client) => Promise<void>;
}

export default function SelectPartyModal({ show, _case, clients, type, availableTags, jurisdiction, onClose, onConfirm }: Props) {
  const singleParty = _case.otherParties?.length === 1 ? _case.otherParties[0] : undefined;
  const [partyId, setPartyId] = useState<string | undefined>();
  const [position, setPosition] = useState<PartyPosition | undefined>();
  const [name, setName] = useState<string | null | undefined>();
  const [tags, setTags] = useState<number[] | null | undefined>();
  const [saving, setSaving] = useState(false);
  const caseApi = useCaseApi();
  const clientApi = useClientApi();
  const { user, firm } = useAuth()!;
  const clientTypeaheadRef = useRef<any>();
  const [selectedClient, setSelectedClient] = useState<Client | undefined>();

  const [, featureFlags] = useConfig();
  const useMultiClient = featureFlags()[MULTI_CLIENT_FF] ? featureFlags()[MULTI_CLIENT_FF](user, firm) : false;

  async function handleConfirm() {
    async function updatePartyAndConfirm(docClient?: Client) {
      if (partyId) {
        // Need to create party
        if (partyId === '-1') {
          // TODO: handle error/retry
          setSaving(true);
          const added = await caseApi.caseCreateParty({ firmId: _case.firmId!, id: _case.id!, party: { name, position, tagIds: tags } });
          await onConfirm(added, docClient);
        } else { // Update?
          let party = _case.otherParties!.find(x => x.id === partyId);
          if (party?.name !== name || party?.position !== position || party?.tagIds !== tags) {
            party = { ...party, name, position, tagIds: tags };
            setSaving(true);
            // TODO: handle error/retry
            await caseApi.casePutParty({ firmId: _case.firmId!, id: _case.id!, partyId, party: { name, position, tagIds: tags } });
          }
          await onConfirm(party!, docClient);
        }
      } else {
        //TODO: error 
      }
    }

    if (useMultiClient && clients) {
      let docClient = selectedClient;
      if (!docClient?.id) {
        setSaving(true);
        docClient = await clientApi.clientPost({ firmId: firm?.id!, client: docClient });
        await caseApi.caseAddClient({firmId: user!.firmId!, id: _case.id!,body: docClient.id!});
        setSelectedClient(docClient);
      }
      if (_case.clientId !== docClient?.id) {
        let newClients = [...(_case?.clients ?? [])];//fyi _case?.clients is returning an array instead of a set
        if (!newClients.includes(docClient!.id!)) {
          setSaving(true);
          await caseApi.caseAddClient({firmId: user!.firmId!, id: _case.id!,body: docClient.id!});
        }
      }
      await updatePartyAndConfirm(docClient);
    }
    else {
      await updatePartyAndConfirm();
    }
  }

  useEffect(() => {
    if (show) {
      setPartyId(_case.otherParties?.length ? singleParty?.id : '-1');
      setPosition(_case.otherParties?.length ? singleParty?.position : undefined);
      setName(_case.otherParties?.length ? singleParty?.name : '');
      setTags(_case.otherParties?.length ? singleParty?.tagIds : []);
    }
  }, [_case.otherParties, show, singleParty])

  function handleClose() {
    handlePartyChange(undefined);
    onClose();
  }

  function handlePartyChange(value: string | undefined) {
    setPartyId(value);
    if (value === '-1') {
      setPosition(undefined);
      setName('');
      setTags([]);
    } else if (value) {
      const party = _case.otherParties!.find(x => x.id === value);
      setPosition(party?.position);
      setTags(party?.tagIds ?? []);
      setName(party?.name);
    } else {
      setPosition(undefined);
      setName(undefined);
      setTags(undefined);
    }
  }

  // need to do this jazz because UserService enums are being returned as strings not numbers like the DocumentService,
  // probably need to change UserService to behave like DocumentService but there are too many cases to handle right now
  const positionValues = position?.split(',').map(item => item.trim()) ?? [];
  function flipPosition(val: PartyPosition) {
    var existing = positionValues.indexOf(val);

    if (existing !== -1) {
      positionValues.splice(existing, 1);
    } else {
      positionValues.push(val);
    }

    if (_case.caseType && partyId === '-1' && !tags?.length && positionValues.find(x => x === PartyPosition.Defendant)) {
      findAndSetDefaultTag();
    }

    if (!positionValues?.length) {
      setPosition(PartyPosition.Unknown);
      return;
    }
    setPosition(positionValues.join(", ") as PartyPosition);
  }

  function findAndSetDefaultTag() {
    let typeClass = jurisdiction.caseClasses?.find(x => x.id === _case.caseType);

    if (typeClass) {
      const found = defaultTags.get(typeClass.id!);

      if (found) {
        setDefaultTag(found);
      }
      return;
    }

    typeClass = jurisdiction?.caseClasses?.find(x => x.subTypes?.find(s => s.id === _case.caseType));

    let type = typeClass!.subTypes?.find(x => x.id === _case.caseType);

    if (type) {
      const found = defaultTags.get(type.id!) ?? defaultTags.get(typeClass!.id!);
      if (found) {
        setDefaultTag(found);
      }
    }
  }

  function setDefaultTag(value: string) {
    const tag = Array.from(availableTags).find(([key, name]) => name === value);

    if (tag) {
      setTags([tag[0]])
    }
  }

  function handleTagChange(value: OptionsType<TagOption>, _: ActionMeta<TagOption>) {
    setTags(value?.map(x => x.value!) ?? []);
  }

  if (!type) {
    return null;
  }

  function handlePropoundingChange(e: Option[]): void {
    const val = e[0];
    if (val) {
      const clientOption = (val as any);
      if ('customOption' in clientOption) {//custom option === create new client
        setSelectedClient({ name: clientOption.text.trim() });
      }
      else {
        const c = (val as Client);
        setSelectedClient(c);
      }
    }
  }

  return (
    <Modal
      show={show}
      onClose={handleClose}
      onConfirm={handleConfirm}
      title={`Create ${getRequestTypeSingular(type, _case.jurisdiction ?? '')}`}
      confirmText={'Continue'}
      showCancelButton={true}
      showConfirmButton={true}
      contentClassName='party-select-modal'
      confirming={saving}
      disableConfirmButton={!name || (!position || position === PartyPosition.Unknown)}
    >
      {<>
        {useMultiClient && clients &&
          (<React.Fragment>
            <div className="client-input-row">
              <label htmlFor='party-select' className='padding form-label'>Propounding Party (Client)</label>
              <Typeahead
                allowNew
                clearButton
                id="field-responding"
                labelKey='text'
                onChange={(e) => handlePropoundingChange(e)}
                size="sm"
                options={clients.map((c): Option => ({ text: c.name ?? "", ...c })) || []}
                ref={clientTypeaheadRef}
              />
            </div>
          </React.Fragment>)
        }
        <div className="client-input-row">
          <label htmlFor='party-select' className='padding form-label'>Responding Party</label>
          <Form.Select id='party-select' onChange={(e) => handlePartyChange(e.target.value)} value={partyId ?? ""}>
            {(_case.otherParties?.length && !singleParty) && <option value="" disabled>Select</option>}
            {_case.otherParties?.map(p => {
              return (<option key={p.id} value={p.id}>{p.name}</option>);
            })}
            <option key='-1' value='-1'>+ Add New Responding Party</option>
          </Form.Select>
        </div>
        <div className="client-input-row">
          {partyId === '-1' && <Form.Control style={{ marginTop: '4px' }} id='client-name' value={name ?? ''} placeholder='Name' onChange={(e) => setName(e.target.value)} />}
        </div>
        <div className="client-input-row">
          <Form.Group>
            <Form.Label className="required padding">Other Party's Position</Form.Label>
            <div className="checkbox-container">
              <span>
                <Form.Check
                  id={'propounding-party-plaintiff'}
                  type={'checkbox'}
                  label="Plaintiff"
                  disabled={!partyId}
                  checked={positionValues?.includes(PartyPosition.Plaintiff) ?? false}
                  onChange={() => flipPosition(PartyPosition.Plaintiff)}
                  isInvalid={position === PartyPosition.Unknown}
                />
              </span>
              <span>
                <Form.Check
                  id={'propounding-party-cross-defendant'}
                  type={'checkbox'}
                  label="Cross-Defendant"
                  disabled={!partyId}
                  checked={positionValues?.includes(PartyPosition.CrossDefendant) ?? false}
                  onChange={() => flipPosition(PartyPosition.CrossDefendant)}
                  isInvalid={position === PartyPosition.Unknown}
                />
              </span>
              <span>
                <Form.Check
                  id={'propounding-party-defendant'}
                  type={'checkbox'}
                  label="Defendant"
                  disabled={!partyId}
                  checked={positionValues?.includes(PartyPosition.Defendant) ?? false}
                  onChange={() => flipPosition(PartyPosition.Defendant)}
                  isInvalid={position === PartyPosition.Unknown}
                />
              </span>
              <span>
                <Form.Check
                  id={'propounding-party-cross-complainant'}
                  type={'checkbox'}
                  label="Cross-Complainant"
                  disabled={!partyId}
                  checked={positionValues?.includes(PartyPosition.CrossComplainant) ?? false}
                  onChange={() => flipPosition(PartyPosition.CrossComplainant)}
                  isInvalid={position === PartyPosition.Unknown}
                />
              </span>
            </div>
          </Form.Group>
        </div>
        <div className="client-input-row">
          <TagsSelect id='case-tags' label="Tags" disabled={!partyId} availableTags={availableTags} selected={tags ?? undefined} onChange={handleTagChange} />
          <p className='note'>Include content specific to this party's persona (e.g., "Employer" or "Insurance Company")</p>
        </div>
      </>}
    </Modal>
  );
}
